import mobiscroll from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { SegmentedGroup, SegmentedItem, Select } from "@mobiscroll/react5";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { API, graphqlOperation } from "aws-amplify";
import React, { Fragment, Suspense, useState } from "react";
import Avatar from "react-avatar";
import { Button, Card, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsInfoCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import Loader from "src/components/ClinicDashboard/common/Loader";
import { subscribeListAppointmentClinicBeep } from "src/components/graphql/GraphQLsubscription";
import Appointment from "../../../assets/icons/Appointment.svg";
import ActionCellRender from "./ActionCellRender";
import "./Appointments.scss";
import DoctorCellRenderer from "./DoctorCellRenderer";
import PatientNameCellRenderer from "./PatientNameCellRenderer";
import arrow from "src/assets/icons/black-arrow.svg";
import { subscription } from "./../../ApolloConnection/ApolloProviderComponent";
//api
import { getDoctors } from "src/components/api/index";
import { listAppointments } from "./api";
import { isMobileOnly } from "react-device-detect";
import { Modal } from "react-bootstrap";
import CardWrapper from "./../Layouts/CardWrapper";
import { useQueryClient } from "react-query";

mobiscroll.settings = {
  theme: "ios",
  themeVariant: "light",
};

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

const AppointmentsCard = ({
  title = true,
  wrapInCard = true,
  setShow = () => {},
  paginationPageSize = 5,
  doctorList = false,
}) => {
  const history = useHistory();

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [displayRecords, setDisplayRecords] = useState("00");
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [doctorData, setDoctorData] = useState([]);
  const [data, setData] = useState(["00"]);
  const [loader, setLoader] = useState(false);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onQuickFilterChanged = () => {
    gridApi.setQuickFilter(document.getElementById("appointmentFilter").value);
  };

  const onPageSizeChanged = async (e) => {
    setDisplayRecords(e.valueText);
  };

  const listTodayAppointments = React.useCallback(
    async (displayRecords, doctorData) => {
      setLoader(true);
      try {
        const t_start = new Date(new Date().setHours(0, 0, 0, 0));
        const t_end = new Date(t_start);
        t_end.setTime(t_start.getTime() + 24 * 60 * 60 * 1000);
        let doctors = [];
        doctorData.forEach((doc) => {
          //eslint-disable-next-line
          doc.checked ? doctors.push(doc.id) : null;
        });
        let _displayRecords = displayRecords;
        if (displayRecords <= 5) {
          _displayRecords = 5;
        }
        const res = await listAppointments(
          doctors,
          +_displayRecords,
          t_start.toISOString(),
          t_end.toISOString(),
          "status=Booked,In-Progress"
        );
        if (res?.data) {
          setRowData(res.data);
          let value =
            res?.data.length < 10 ? `0${res?.data.length}` : res.data.length;
          if (displayRecords != value) setDisplayRecords(value);
          setData((prevProps) => {
            let arr = [];
            for (let index = 0; index <= res.totalCount; index++) {
              let value = index < 10 ? `0${index}` : index;
              arr.push(value);
            }
            return arr;
          });
          setTotalRecords(res.totalCount);
        }
      } catch (e) {
        console.log(e);
      }
      setLoader(false);
    },
    [setLoader, displayRecords, setRowData, listAppointments]
  );

  React.useEffect(() => {
    const clinicId = sessionStorage.getItem("clinic-id");
    console.log(clinicId);
    subscription(
      subscribeListAppointmentClinicBeep,
      { clinicId },
      async (response) => {
        if (
          !(
            response.data.subscribeListAppointmentClinicBeep.statusCode >=
              200 &&
            response.data.subscribeListAppointmentClinicBeep.statusCode < 300
          ) ||
          response.data.subscribeListAppointmentClinicBeep.errors != null
        ) {
          return;
        }
        setLoader(true);
        try {
          listTodayAppointments(displayRecords, doctorData);
          console.log("RUNNING");
        } catch (err) {
          console.log(err);
        }

        setLoader(false);
        //fetch notifications
      },
      (error) => {
        console.log(error);
      }
    );
  }, [displayRecords, doctorData]);

  React.useEffect(() => {
    listTodayAppointments(displayRecords, doctorData);
    console.log("RUNNING ANOTHER USE EFFECT");
  }, [doctorData, displayRecords]);

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 100,
    suppressMovable: true,
  };

  const queryClient = useQueryClient();
  React.useEffect(() => {
    (async () => {
      const res = await queryClient.fetchQuery({
        queryKey: ["listDoctors"],
        queryFn: getDoctors,
        staleTime: 1000 * 60 * 60,
      });


      setDoctorData(
        res.map((item) => {
          return { ...item, checked: false };
        })
      );
    })();
  }, []);
  const handleChange = (e) => {
    let res = doctorData;
    res = res.map((doctor) => {
      return doctor.id === e.target.name
        ? { ...doctor, checked: e.target.checked }
        : doctor;
    });
    setDoctorData(res);
  };
  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {};

  const routeChange = () => {
    history.push("/calendar");
  };
  return (
    <Fragment>
      <Loader show={loader} />
      <CardWrapper
        headerComponent={
          title && (
            <CardHeader className="customCardHeader d-flex justify-content-between">
              <CardTitle className="customCardTitle d-flex">
                {!isMobileOnly && (
                  <>
                    <Image src={Appointment} alt="" className="mr-2" />{" "}
                    Appointments&nbsp;
                  </>
                )}
                <OverlayTrigger
                  key="bottom"
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-bottom">
                      {isMobileOnly && (
                        <p className="d-flex align-items-center justify-content-start">
                          Appointments
                        </p>
                      )}
                      <p className="d-flex align-items-center justify-content-start">
                        <span
                          style={{
                            background: "#dc3545",
                            width: "10px",
                            height: "20px",
                          }}
                        >
                          &nbsp; &nbsp;
                        </span>
                        &nbsp; - Late
                      </p>
                      <p className="d-flex align-items-center justify-content-start">
                        <span
                          style={{
                            background: "#1aab73",
                            width: "10px",
                            height: "20px",
                          }}
                        >
                          &nbsp;
                        </span>
                        &nbsp; - Ontime
                      </p>
                      <p className="d-flex align-items-center justify-content-start">
                        <span
                          style={{
                            background: "#ffc107",
                            width: "10px",
                            height: "20px",
                          }}
                        >
                          &nbsp; &nbsp;
                        </span>
                        &nbsp; - Checked in
                      </p>
                      <p className="d-flex align-items-center justify-content-start">
                        <span
                          style={{
                            background: "#6c757d",
                            width: "10px",
                            height: "20px",
                          }}
                        >
                          &nbsp; &nbsp;
                        </span>
                        &nbsp; - No status
                      </p>
                    </Tooltip>
                  }
                >
                  <BsInfoCircleFill />
                </OverlayTrigger>
              </CardTitle>
              <SegmentedGroup
                class="doctor-list d-flex mr-3 ml-3"
                style={{ padding: "0 2px" }}
                onChange={handleChange}
                select="multiple"
              >
                {doctorData.map((doctor) => {
                  return (
                    <SegmentedItem
                      className="doctor-detail"
                      name={doctor.id}
                      id={doctor.id}
                      value={JSON.stringify(doctor)}
                      checked={doctor?.checked}
                      style={{ padding: "0", margin: "0" }}
                    >
                      <Avatar
                        color="#c06c84"
                        round
                        size="32"
                        name={`${doctor?.firstName} ${doctor?.lastName}`}
                        src={doctor.profilePicture}
                      />
                      <span className="md-header-filter-name md-header-filter-name-1 ml-2">
                        {doctor.firstName} {doctor.lastName}
                      </span>
                    </SegmentedItem>
                  );
                })}
              </SegmentedGroup>
              {isMobileOnly && (
                <button
                  className="docg-outline-btn"
                  onClick={() => setShow(true)}
                >
                  <img width="10px" src={arrow} alt="" />
                </button>
              )}
              {!isMobileOnly && (
                <>
                  <div className="d-flex align-items-center">
                    <div className="docg-table-search">
                      <input
                        type="text"
                        onInput={() => onQuickFilterChanged()}
                        id="appointmentFilter"
                        placeholder="Search..."
                      />
                    </div>
                    <Button
                      className="theme-button btn-xs"
                      onClick={routeChange}
                    >
                      <span className="material-icons pr-1">
                        add_circle_outline
                      </span>{" "}
                      Add
                    </Button>
                  </div>
                </>
              )}
            </CardHeader>
          )
        }
        wrap={wrapInCard}
        cardClass="docg-card docg-appointments"
        cardBodyClass="docg-card-body p-0"
      >
        {!isMobileOnly && (
          <div className="records-display-new">
            <span>Showing&nbsp;</span>
            <label className="record-selection-dropdown" htmlFor="">
              {totalRecords >= 0 && (
                <Select
                  touchUi={false}
                  onChange={onPageSizeChanged}
                  value={displayRecords}
                  data={data}
                />
              )}
            </label>
            <span>&nbsp;Records</span>
          </div>
        )}
        {doctorList && (
          <div className="overflow-auto w-100">
            <SegmentedGroup
              class="doctor-list d-flex mr-3 ml-3 "
              style={{ padding: "0 2px" }}
              onChange={handleChange}
              select="multiple"
            >
              {doctorData.map((doctor) => {
                return (
                  <SegmentedItem
                    className="doctor-detail"
                    name={doctor.id}
                    id={doctor.id}
                    value={JSON.stringify(doctor)}
                    checked={doctor?.checked}
                    style={{ padding: "0", margin: "0" }}
                  >
                    <Avatar
                      color="#c06c84"
                      round
                      size="32"
                      name={`${doctor?.firstName} ${doctor?.lastName}`}
                      src={doctor.profilePicture}
                    />
                    <span className="md-header-filter-name md-header-filter-name-1 ml-2">
                      {doctor.firstName} {doctor.lastName}
                    </span>
                  </SegmentedItem>
                );
              })}
            </SegmentedGroup>
          </div>
        )}
        <div
          className="ag-theme-alpine"
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <AgGridReact
            rowData={rowData}
            defaultColDef={defaultColDef}
            frameworkComponents={{
              actionCellRender: ActionCellRender,
              patientCellRenderer: PatientNameCellRenderer,
              doctorCellRenderer: DoctorCellRenderer,
            }}
            defaultColGroupDef={defaultColGroupDef}
            enableRangeSelection={true}
            pagination={true}
            paginationPageSize={paginationPageSize}
            onGridReady={onGridReady}
            domLayout={"autoHeight"}
            animateRows={true}
          >
            <AgGridColumn
              headerName="Patient Name"
              headerTooltip="Patient Name"
              field="patient"
              minWidth="250"
              cellRenderer="patientCellRenderer"
              valueGetter={(params) => {
                return `${params.data.patient.firstName} ${params.data.patient.lastName}`;
              }}
              tooltipValueGetter={(params) => {
                return `${params.data.patient.firstName} ${params.data.patient.lastName}`;
              }}
            ></AgGridColumn>
            {!isMobileOnly && (
              <AgGridColumn
                headerName="Doctor Name"
                headerTooltip="Doctor Name"
                field="doctor"
                cellRenderer="doctorCellRenderer"
                valueGetter={(params) => {
                  return `${params.data.doctor.firstName} ${params.data.doctor.lastName}`;
                }}
                tooltipValueGetter={(params) => {
                  return `${params.data.doctor.firstName} ${params.data.doctor.lastName}`;
                }}
              ></AgGridColumn>
            )}

            <AgGridColumn
              headerName="Time"
              headerTooltip="Time"
              field="start"
              valueFormatter={(params) => {
                return new Date(params.value).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                });
              }}
              tooltipValueGetter={(params) => {
                return new Date(params.value).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                });
              }}
            ></AgGridColumn>
            <AgGridColumn
              headerName="P. Type"
              headerTooltip="Patient Type"
              field="newPatient"
              valueFormatter={(params) => (params.value ? "New" : "Repeat")}
              tooltipValueGetter={(params) => (params.value ? "New" : "Repeat")}
            ></AgGridColumn>
            <AgGridColumn
              headerName="Payment Mode"
              headerTooltip="Payment Mode"
              field="paymentMode"
              tooltipField="paymentMode"
            ></AgGridColumn>
            <AgGridColumn
              headerName="Consl. Type"
              headerTooltip="Consultation Type"
              field="consultationType"
              valueFormatter={(params) => {
                return params?.value === "Face2Face"
                  ? "In person at clinic"
                  : params?.value;
              }}
              tooltipValueGetter={(params) => {
                return params?.value === "Face2Face"
                  ? "In person at clinic"
                  : params?.value;
              }}
            ></AgGridColumn>
            <AgGridColumn
              headerName=""
              field="actions"
              cellRenderer="actionCellRender"
              valueGetter={(params) => {
                return {
                  appointmentId: params.data.id,
                  patientDetails: params.data.patient,
                };
              }}
              filter={false}
              sortable={false}
            ></AgGridColumn>
          </AgGridReact>
        </div>
      </CardWrapper>
    </Fragment>
  );
};

const AppointmentsPopup = ({ show, setShow }) => {
  return (
    <Modal
      show={show}
      onHide={setShow}
      dialogClassName="history-dialog-modal"
      aria-labelledby="example-custom-modal-styling-title"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="ModalTitle">Appointments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Suspense fallback={<div>Loading...</div>}>
          <AppointmentsCard
            wrapInCard={false}
            title={false}
            paginationPageSize={20}
            doctorList={true}
          />
        </Suspense>
      </Modal.Body>
    </Modal>
  );
};
const Appointments = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      {isMobileOnly && <AppointmentsPopup show={show} setShow={setShow} />}
      <AppointmentsCard show={show} setShow={setShow} />
    </>
  );
};
export default Appointments;
