import mobiscroll, { snackbar, toast } from "@mobiscroll/react";
import { Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { getQuery as getQueryUnAuth } from "src/components/ApolloConnection/UnauthorizedConnection/ApolloProviderSignup";
import Loader from "src/components/ClinicDashboard/common/Loader";
import {
  setLoginStatus,
  setSubscription,
  setUserData,
} from "src/store/GlobalReducer";
import { useStore } from "src/store/Store";
import line from "../../assets/Lines.svg";
import grid from "../../assets/grid.svg";
import login_icon from "../../assets/login icon  human.svg";
import doctor from "../../assets/login-doctor.svg";
import signin_circle from "../../assets/signin-circle.svg";
import { getQuery } from "../../components/ApolloConnection/ApolloProviderComponent";
import "../ClinicDashboard/Layouts/Card.css";
import { GET_FILE_FOR_DOCG, USER_DATA } from "../graphql/GraphQLquery";
import "./Auth.scss";
import ChangeTempPassword from "./ChangeTempPassword";

import { Checkbox } from "@mobiscroll/react5";
import { Modal } from "react-bootstrap";
import { useQuery, useQueryClient } from "react-query";
import "../ApolloConnection/UnauthorizedConnection/ApolloProviderSignup";
import SetMFAasTOTP from "./SetMFAasTOTP";
import OtpVerification from "./OtpVerification";
import { signIn, signOut } from "@aws-amplify/auth";
import PDFViewer from "./../ClinicDashboard/Layouts/PDFViewer";
import {
  fetchMFAPreference,
  fetchUserAttributes,
  getCurrentUser,
} from "@aws-amplify/auth";
function SignIn() {
  const { globalState, dispatch } = useStore();
  const history = useHistory();
  const [user, setUser] = useState({});
  const [congnitoUser, setCognitoUser] = useState(null);
  const [authType, setAuthType] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [authenticating, setAuthenticating] = useState(false);
  const [errorLogin, setErrorLogin] = useState("");
  const [changeTempPassword, setChangeTempPassword] = useState(null);
  const [loader, setLoader] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showTNC, setShowTNC] = useState(false);
  const [userData, setMFAUserData] = useState(null);
  const [mfa, setMfa] = useState(null);
  const [showPDF, setShowPDF] = useState(false);

  const env = process.env.REACT_APP_ENVIRONMENT;
  const tnc_file = process.env.REACT_APP_TNC;

  const checkMFA = async () => {
    const user = await getCurrentUser();
    setMFAUserData(user);
    const mfa = await fetchMFAPreference();
    return mfa;
  };

  const minMaxLength = (text, minLength, maxLength) => {
    let result = !text || text.length < minLength;
    if (maxLength) result = result || text.length < minLength;
    return result;
  };

  const validEmail = (text) => {
    const regex = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    return !regex.test(text);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let currentFormErrors = {};

    switch (name) {
      case "username":
        setUser({ ...user, username: value });
        if (!value || validEmail(value)) {
          currentFormErrors[name] = `Email address is invalid`;
        } else {
          delete currentFormErrors[name];

          localStorage.setItem("username", value);
        }
        break;
      case "password":
        setUser({ ...user, password: value });
        if (minMaxLength(value, 8)) {
          currentFormErrors[name] = `Password should have minimum 8 characters`;
        } else {
          delete currentFormErrors[name];
        }
        break;
      default:
        break;
    }
    setFormErrors(currentFormErrors);
  };

  const forgotPassword = () => {
    history.push("/forgot-password");

    // if (user.username === null) {
    //   setFormErrors({
    //     username: "Please enter your username for password recovery",
    //   });
    // } else {
    //   toast({
    //     message: "OTP has been sent to your registered number",
    //     color: "success",
    //   });
    //   sendOTP(user.username);
    //   localStorage.setItem("username", user.username);
    // }
  };

  // async function sendOTP(username) {
  //   Auth.forgotPassword(username)
  //     .then((data) => {
  //       console.log(data);
  //       window.location = "/forgot-password";
  //     })
  //     .catch((err) => console.log(err));
  // }

  const signUp = () => {
    window.location = "sign-up";
  };

  const login = () => {
    if (user.username === null || user.password === null) {
      if (user.username === null) {
        setFormErrors({
          username: "This field is required",
        });
      }

      if (user.password === null) {
        setFormErrors({
          password: "This field is required",
        });
      }

      if ((user.username === null) & (user.password === null)) {
        setFormErrors({
          username: "This field is required",
          password: "This field is required",
        });
      }
    } else {
      toast({
        message: "Authenticating",
        color: "success",
      });

      setErrorLogin("");
      setAuthenticating(true);
      _signIn(user.username, user.password);
    }
  };
  const getUserInfo = async () => {
    return await getQuery(USER_DATA);
  };

  const queryClient = useQueryClient();
  async function onLogin() {
    setLoader(true);
    try {
      const { data: _response } = await queryClient.fetchQuery({
        queryKey: ["getUserInfo"],
        queryFn: getUserInfo,
        onSettled: () => console.log("called from signin route"),
      });
      let response = JSON.parse(JSON.stringify(_response));
      console.log(response);
      response = response.getUserInfo.data;
      dispatch(setSubscription(response.subscriptionStatus));
      let roles = response.roles;
      let userData = {};
      if (roles.filter((role) => role == "Admin").length > 0) {
        // Change the login status
        // myContext.setLoginStatus(true);
        dispatch(setLoginStatus(true));
        sessionStorage.setItem("loginStatus", true);
        sessionStorage.setItem(
          "subscriptionStartTime",
          response.subscriptionStartTime
        );
        const { userId } = await getCurrentUser();
        const { email, phone_number } = await fetchUserAttributes();
        // Update the user email
        // myContext.setUserEmail(username);
        userData.email = email;
        // Update the user id
        // myContext.setUserId(user.attributes.sub);
        userData.id = userId;
        // Update the user mobile
        // myContext.setUserMobile(user.attributes.phone_number);
        userData.mobile = phone_number;
        sessionStorage.setItem("userRole", roles);
        sessionStorage.setItem("userData", JSON.stringify(userData));
        sessionStorage.setItem("role-id", response.profileId);
        sessionStorage.setItem("clinic-id", response.clinicId);
        sessionStorage.setItem(
          "subscriptionStatus",
          response.subscriptionStatus
        );
        userData.roles = roles;
        dispatch(setUserData(userData));
        dispatch(setSubscription(response.subscriptionStatus));
        // myContext.setUserRoles(roles)
        if (response.subscriptionStatus === "created") {
          history.push("/subscription");
        } else if (
          response.subscriptionStatus === "expired" ||
          response.subscriptionStatus === "cancelled"
        ) {
          history.push("/subscription/failure");
        } else if (response.subscriptionStatus == null) {
          history.push("/subscription/new");
        } else {
          history.push("/dashboard");
        }
      } else {
        // history.push("/dashboard");
        alert("You have entered credentials for another role.");
      }
    } catch (error) {
      snackbar({
        message: "Some error occurred while trying to login",
        color: "danger",
      });
      console.log("Error: ", error);
    }
    setLoader(false);
  }

  async function _signIn(username, password) {
    setLoader(true);
    try {
      await signOut();
      console.log("here");
      const { isSignedIn, nextStep } = await signIn({ username, password });
      console.log(user);
      if (
        nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
      ) {
        setChangeTempPassword(user);
      } else if (
        nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_SMS_CODE" ||
        nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE" ||
        nextStep.signInStep === "CONTINUE_SIGN_IN_WITH_MFA_SELECTION"
      ) {
        if (
          nextStep.signInStep === "CONTINUE_SIGN_IN_WITH_MFA_SELECTION" ||
          nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE"
        )
          setAuthType("TOTP");
        else setAuthType("SMS");
        setCognitoUser(user);
        // history.push("/otp-verification");
      } else {
        if (env !== "dev") {
          let mfa = await checkMFA();
          if (mfa === "NOMFA") {
            setMfa(true);
          } else onLogin();
        } else onLogin();
      }
    } catch (error) {
      console.log(error);
      setAuthenticating(false);
      snackbar({
        message: error.message,
        color: "danger",
      });
    }
    setLoader(false);
  }

  if (mfa) {
    return (
      <>
        <SetMFAasTOTP userData={userData} onSuccess={onLogin} />
      </>
    );
  }
  if (congnitoUser) {
    return (
      <>
        <Loader show={loader} />
        <OtpVerification
          cognitoObject={congnitoUser}
          onSuccess={onLogin}
          mfaType={authType}
        />
      </>
    );
  }

  if (changeTempPassword) {
    return <ChangeTempPassword user={changeTempPassword} />;
  }
  return (
    <Fragment>
      <Loader show={loader} />
      <PDFViewer
        link={tnc_file}
        show={showPDF}
        setShow={setShowPDF}
        fileName="Terms and Conditions for Clinic"
        customButton={
          <>
            <button
              onClick={() => {
                setShowPDF(false);
                setChecked(true);
              }}
              className="docg-save-btn"
            >
              Accept
            </button>
          </>
        }
      />
      <Helmet>
        <title> Login | Clinic</title>
      </Helmet>

      <div className="mbsc-grid">
        <div className="mbsc-card main-card-wrapper">
          <div className="signup-wrapper">
            <div className="mbsc-row">
              <div className="mbsc-col-xl-5 doctor-images-left mbsc-col-12 p-0">
                <div className="left-signup-div" id="reset-password-page"></div>
                <img alt="" src={doctor} className="left-signup-img" />
              </div>

              <div className="mbsc-col-xl-7 mbsc-col-12 signup-right">
                <img alt="" src={grid} className="grid-top" />
                <img alt="" src={signin_circle} className="signin-circle" />
                <div className="bottom-circle" />
                <div className="top-circle" />
                <div className="right-signup-div">
                  <div className="get-started-wrapper">
                    <p className="get-txt">Welcome to Clinic Portal!</p>
                    <p
                      style={{ color: "#757575" }}
                      className="signup-link mt-4"
                    >
                      To login, please enter your email and password
                    </p>
                    <p className="connect-txt"></p>
                    {/* <br /> */}
                    {/* <br /> */}
                    <br />
                    <div className="signup-wrapper-inner">
                      <mobiscroll.Input
                        inputStyle="underline"
                        labelStyle="floating"
                        placeholder=""
                        name="username"
                        type="email"
                        onChange={handleChange}
                        valid={!formErrors.username}
                        errorMessage={formErrors.username}
                        disabled={authenticating}
                      >
                        Email Address*
                      </mobiscroll.Input>
                      <br />
                      <mobiscroll.Input
                        inputStyle="underline"
                        labelStyle="floating"
                        passwordToggle={true}
                        type="password"
                        name="password"
                        onChange={handleChange}
                        valid={!formErrors.password}
                        errorMessage={formErrors.password}
                        disabled={authenticating}
                        className="input-style"
                        style={{ marginBottom: "10px" }}
                      >
                        Password*
                      </mobiscroll.Input>
                      <br />
                      <button
                        className="transparent-button"
                        onClick={forgotPassword}
                      >
                        Forgot Password?
                      </button>
                      <br />
                      <div className="d-flex w-full align-items-center justify-content-center">
                        <div className="custom-repeat-checkbox-cont mr-n4">
                          <Checkbox
                            value="tnc"
                            checked={checked}
                            onChange={(e) => {
                              setChecked((prev) => !prev);
                              setShowPDF(true);
                            }}
                          ></Checkbox>
                        </div>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#6c5b7b",
                            fontFamily: "Poppins",
                          }}
                        >
                          Terms and conditions
                        </span>
                      </div>

                      <button
                        className="docg-save-btn d-flex bg-purple  align-items-center justify-content-center"
                        onClick={login}
                        disabled={authenticating || !checked}
                      >
                        <img alt="" src={login_icon} />
                        &nbsp;&nbsp; Login
                      </button>
                      <br />

                      <p className="text-danger text-center">{errorLogin}</p>

                      <p className="signup-link">
                        Signin as a:
                        <span>
                          <button
                            className="transparent-button"
                            id="signup-btn-small"
                            onClick={() => {
                              window.open(
                                process.env.REACT_APP_DOCTOR_URL,
                                "_blank"
                              );
                            }}
                          >
                            Doctor
                          </button>
                        </span>
                      </p>
                      <button
                        className="transparent-button"
                        onClick={() => {
                          history.push("/sign-up");
                        }}
                      >
                        Sign up?
                      </button>
                    </div>
                  </div>
                </div>
                <img
                  alt=""
                  src={line}
                  className="lines-bottom"
                  style={{ marginLeft: "8%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SignIn;
