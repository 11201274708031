import { RadioGroup, Select } from "@mobiscroll/react5";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import signup_icon from "src/assets/Signup icon  human.svg";
import { getQuery } from "src/components/ApolloConnection/ApolloProviderComponent";
import { PlanCard } from "src/components/Authentication/signup/SignupSteps/Step3";
import Loader from "src/components/ClinicDashboard/common/Loader";
import { USER_DATA } from "src/components/graphql/GraphQLquery";
import { setSubscription } from "src/store/GlobalReducer";
import { useStore } from "src/store/Store";
import {
  createRazorpaySubscription,
  listAllRazorpayPlans,
  updateProfile,
} from "./api";

const NewSubscription = ({ setCurrentStep }) => {
  const history = useHistory();
  const { globalState, dispatch } = useStore();
  const queryClient = useQueryClient();

  const [loader, setLoader] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const getUserInfo = async () => {
    return await getQuery(USER_DATA);
  };
  const handleSubmit = async () => {
    setLoader(true);
    const res = await updateProfile({ subscriptionType: selectedPlan });
    if (res) {
      const _res = await createRazorpaySubscription();
      if (_res) {
        const { data: _response } = await queryClient.fetchQuery({
          queryKey: ["getUserInfo"],
          queryFn: getUserInfo,
        });
        dispatch(
          setSubscription(
            _response.getUserInfo.data.subscriptionStatus
          )
        );
        history.push("/subscription");
      }
    }
    setLoader(false);
  };

  useEffect(() => {
    dispatch(setSubscription(null));
  }, []);

  const { data: plans } = useQuery(["fetch subscriptions ids"], async () => {
    const res = await listAllRazorpayPlans();
    return res;
  });

  return (
    <>
      <Loader show={loader} />

      <div className="mbsc-grid h-100">
        <div className="d-flex justify-content-center align-items-center flex-column w-100">
          <div className="mbsc-row">
            <div className="signup-wrapper-inner">
              <RadioGroup name="group">
                <div className="mbsc-row d-flex flex-col justify-content-center align-items-center overflow-auto flex-wrap py-2">
                  <div className="mbsc-col-12">
                    <Select
                      data={plans?.map((plan) => plan.subscriptionType)}
                      label="Plan Type*"
                      inputProps={{
                        inputStyle: "box",
                        labelStyle: "stacked",
                        placeholder: "Please select",
                      }}
                      touchUi={false}
                      value={selectedPlan}
                      onChange={(e) => {
                        setSelectedPlan(e.value);
                      }}
                    />
                  </div>
                  <div className="justify-content-center align-items-center w-100 d-flex mt-3">
                    {selectedPlan ? (
                      <PlanCard
                        plan={plans?.find(
                          (itm) => itm?.subscriptionType == selectedPlan
                        )}
                        plans={plans}
                        selectedPlan={selectedPlan}
                      />
                    ) : (
                      "Please select new plan"
                    )}
                  </div>
                </div>
              </RadioGroup>

              <button
                onClick={handleSubmit}
                className="docg-save-btn d-flex justify-content-center mt-5"
              >
                <img src={signup_icon} alt="" />
                &nbsp;&nbsp; Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewSubscription;
