import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Eventcalendar,
  SegmentedGroup,
  SegmentedItem,
  momentTimezone,
  setOptions,
} from "@mobiscroll/react5";
import moment from "moment-timezone";
import React, { useCallback } from "react";
import { Card } from "react-bootstrap";
import logo from "src/assets/icons/ScheduleTimingIcon.svg";
import UserTwo from "src/assets/icons/UserTwo.png";
import { setLoading } from "src/store/GlobalReducer";
import { useStore } from "src/store/Store";
import "./StaffRooster.css";

//api
import { getDoctors } from "src/components/api/index";
import { listClinicSchedules } from "./api";
import { isMobileOnly } from "react-device-detect";
import { useQueryClient } from "react-query";

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;
setOptions({
  theme: "ios",
  themeVariant: "light",
});
// setup Mobiscroll Moment plugin
momentTimezone.moment = moment;
function App() {
  const { globalState, dispatch } = useStore();
  const [appointments, setAppointments] = React.useState([]);
  const [doctors, setDoctors] = React.useState([]);
  const [view, setView] = React.useState("day");
  const [calView, setCalView] = React.useState({
    timeline: {
      type: "day",
      timeCellStep: 30,
    },
  });

  const changeView = (event) => {
    let calView;

    switch (event.target.value) {
      case "month":
        calView = {
          timeline: { type: "month", labels: true },
        };
        break;
      case "week":
        calView = {
          timeline: { type: "week", timeCellStep: 30 },
        };
        break;
      case "day":
        calView = {
          timeline: { type: "day", timeCellStep: 30 },
        };
        break;
    }

    setView(event.target.value);
    setCalView(calView);
  };
  const customWithNavButtons = () => {
    return (
      <React.Fragment>
        <CardHeader
          className="d-flex justify-content-between customCardHeader w-100"
          style={{ backgroundColor: "#dee2e6" }}
        >
          <CardTitle className="customCardTitle">
            <img
              style={{ maxWidth: "20px", maxHeight: "20px" }}
              src={logo}
              className="mr-2"
              alt=""
            />{" "}
            Staff Roster
          </CardTitle>
          {!isMobileOnly && (
            <div className="cal-header-picker d-flex align-items-center">
              <CalendarNav className="cal-header-nav" />
              <SegmentedGroup value={view} onChange={changeView}>
                <SegmentedItem value="day">Day</SegmentedItem>
                <SegmentedItem value="week">Week</SegmentedItem>
                <SegmentedItem value="month">Month</SegmentedItem>
              </SegmentedGroup>
            </div>
          )}
          <div className="">
            <CalendarPrev className="cal-header-prev" />
            <CalendarToday className="cal-header-today" />
            <CalendarNext className="cal-header-next" />
          </div>
        </CardHeader>
      </React.Fragment>
    );
  };

  const renderScheduleEvent = useCallback((data) => {
    console.log(data);
    return (
      <div
        className="docg-custom-schedule-event d-flex w-100"
        style={{
          background: "#c06c84",
          color: "#fff",
        }}
      >
        <div
          style={{ fontSize: "0.8rem", textAlign: "center" }}
          className="docg-custom-event-time d-flex justify-content-start align-items-center pt-1 pb-1 pl-2 pr-2 w-100"
        >
          <img
            style={{ width: "auto", maxHeight: "2em" }}
            src={
              data.currentResource.profilePicture
                ? data.currentResource.profilePicture
                : UserTwo
            }
            className="mr-1"
            alt="doctor's profile"
          />
          {data.start} to {data.end}
        </div>
      </div>
    );
  });
  const renderLabel = useCallback((data) => {
    return (
      <div
        className="docg-custom-schedule-event d-flex"
        style={{
          background: "#1AAB73",
          color: "#fff",
        }}
      >
        <div className="docg-custom-event-title">Open Schedule</div>
      </div>
    );
  });

  const renderCustomResource = (data) => {
    return (
      <>
        <div className="">
          {data.title} {data.firstName} {data.lastName}
        </div>
      </>
    );
  };
  const renderCustomResourceHeader = (data) => {
    return (
      <>
        <div className="">Doctor Name</div>
      </>
    );
  };
  const queryClient = useQueryClient();
  React.useEffect(() => {
    (async () => {
      const res = await queryClient.fetchQuery({
        queryKey: ["listDoctors"],
        queryFn: getDoctors,
        staleTime: 1000 * 60 * 5,
      });

      setDoctors(
        res?.map((item) => {
          return { ...item };
        })
      );
    })();
  }, []);
  
  const onPageLoading = async (data) => {
    // dispatch(setLoading(true));
    try {
      console.log(data, data.firstDay, data.lastDay);
      const schedules = await listClinicSchedules({
        start: data.firstDay.toISOString(),
        end: data.lastDay.toISOString(),
      });
      console.log(schedules);
      setAppointments(schedules);
    } catch (err) {
      console.error(err);
    }
    // dispatch(setLoading(false));
  };
  return (
    <Card className="docg-schedule-card docg-staff-rooster">
      <Eventcalendar
        renderHeader={customWithNavButtons}
        renderScheduleEvent={renderScheduleEvent}
        renderLabel={renderLabel}
        renderEventContent={renderLabel}
        view={calView}
        cssClass="md-switching-view-cont"
        resources={doctors}
        renderResource={renderCustomResource}
        renderResourceHeader={renderCustomResourceHeader}
        data={appointments}
        clickToCreate={false}
        dragToCreate={false}
        showEventTooltip={false}
        displayTimezone="local"
        dataTimezone="utc"
        timezonePlugin={momentTimezone}
        onPageLoading={onPageLoading}
      />
    </Card>
  );
}

export default App;
