import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Route, useHistory } from "react-router-dom";
import { getQuery } from "src/components/ApolloConnection/ApolloProviderComponent";
import TnC from "src/components/ClinicDashboard/Profile/TnC";
import { getMyProfile } from "src/components/ClinicDashboard/Profile/api";
import { USER_DATA } from "src/components/graphql/GraphQLquery";
import { setSubscription } from "src/store/GlobalReducer";
import { useStore } from "src/store/Store";

const PrivateRoute = ({ component: Component, module, key, ...rest }) => {
  const { globalState, dispatch } = useStore();
  const { data, isLoading } = useQuery(["Clinic details"], getMyProfile);
  const getUserInfo = async () => {
    console.log("called from private route");
    return await getQuery(USER_DATA);
  };

  const queryClient = useQueryClient();
  useEffect(() => {
    (async () => {
      const { data: _response } = await queryClient.fetchQuery({
        queryKey: ["getUserInfo"],
        queryFn: getUserInfo,
        onSuccess: () => console.log("called from signin route"),
        staleTime: 1000 * 60 * 5, // Data will be considered fresh for 5 minutes

      });
      dispatch(setSubscription(_response.getUserInfo.data.subscriptionStatus));
    })();
  }, []);

  const history = useHistory();

  const path = rest?.path.split("/");
  let url;
  if (globalState?.subscription === "created") {
    url = "/subscription";
  } else if (
    globalState?.subscription === "expired" ||
    globalState?.subscription === "cancelled"
  ) {
    url = "/subscription/failure";
  } else if (globalState?.subscription == null) {
    url = "/subscription/new";
  }
  const [offline, setOffline] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("online", () => {
  //     if (offline) setOffline(false);
  //   });
  //   window.addEventListener("offline", () =>{if(!offline) setOffline(true)});
  //   return () => {
  //     window.removeEventListener("online");
  //     window.removeEventListener("offline");
  //   };
  // }, []);

  if (offline) {
    return "YOU ARE OFFLINE";
  }

  if (isLoading) return <></>;

  if (
    !path.includes("subscription") &&
    globalState?.subscription !== "authenticated"
  ) {
    return (
      <Route
        {...rest}
        exact
        key={key}
        render={(props) => (
          <div className="mbsc-col-12 d-flex justify-content-center align-items-center flex-column w-100 docg-vh-standard">
            <div className="mt-3">
              {" "}
              Please complete subscription first to get access to entire
              dashboard
            </div>
            <button onClick={() => history.push(url)}>
              Go to Subscription
            </button>
          </div>
        )}
      />
    );
  }

  if (
    !data?.clinicTermsAndConditionsForApp?.file
    // ) ||
    // !(
    //   data?.patientTermsAndConditionsForWeb ||
    //   data?.patientTermsAndConditionsForWebGeneric
    // ) ||
    // !(
    //   data?.termsAndConditionsForApp || data?.termsAndConditionsForAppGeneric
    // ) ||
    // !(data?.privacyPolicyForApp || data?.privacyPolicyForAppGeneric) ||
    // !(data?.disclaimerPatient || data?.disclaimerPatientGeneric)
  ) {
    return (
      <Route
        {...rest}
        exact
        key={key}
        render={(props) => (
          <div className="mbsc-col-12 d-flex justify-content-start align-items-center flex-column w-100 docg-vh-standard">
            <TnC show={true} />
            <span className="mt-3">
              {" "}
              Now,{" "}
              <button onClick={() => window.location.reload(false)}>
                <div>Please Agree terms and conditions to proceed</div>
                <i>Click here to Refresh</i>
              </button>
            </span>
          </div>
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      exact
      key={key}
      render={(props) => <Component {...props} />}
    />
  );
};

export default PrivateRoute;
